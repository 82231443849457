<template>
  <div>
    <page-title :icon=icon :heading="$t('generic.lang_AdditionalStatistics')" :subheading="$t('generic.lang_AdditionalStatistics')" :show-add-button="true" :permission-add="true"></page-title>
    <div class="app-main__inner">
      <AdditionalStatisticsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import AdditionalStatisticsComponent
  from "@/components/settings/cashier/additionalstatistics/AdditionalStatisticsComponent";
export default {
  name: "AdditionalStatistics",
  components: {AdditionalStatisticsComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-tools icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>